import React, { useEffect, useRef } from 'react';
import './styles/Services.css';
import ServicesCard from './ServicesCard';

import DevelopmentIcon from'../assets/icons/DevelopmentIcon';
import ConsultingIcon from '../assets/icons/ConsultingIcon';
import PlusIcon from '../assets/icons/PlusIcon';
import CloudIcon from '../assets/icons/CloudIcon';
import BlogIcon from '../assets/icons/BlogIcon';
import UxIcon from '../assets/icons/UxIcon';
import AccessibilityIcon from '../assets/icons/AccessibilityIcon';

function Services() {
  const servicesRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            entry.target.classList.add('slide-up');
            observer.unobserve(entry.target); // Unobserve the element after it has been observed
          }, index * 150); // Delay based on index
        }
      });
    }, { threshold: 0.01 });

    const items = servicesRef.current.querySelectorAll('.service-card, .services-heading');

    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  return (
    <section className="services-section" ref={servicesRef}>
      <div className="container">
        <div className="row">
          <h2 className="services-heading">Your Gateway to Advanced Web Solutions</h2>
        </div>
        <div className="row services-row">
            <ServicesCard icon={<DevelopmentIcon/>} title={'Website Design and Development'} description={"Specializing in both new website creation and existing site revamps, we focus on crafting visually appealing, user-friendly, and responsive web solutions."} />
            <ServicesCard icon={<ConsultingIcon/>} title={'Ongoing Technical Support'} description={"Providing reliable and continuous support to ensure your website is up-to-date, secure, and operating smoothly at all times."} />
            <ServicesCard icon={<CloudIcon/>} title={'Hosting and Domain Management'} description={"Simplifying the technical side of your web presence with hosting management and domain acquisition."} />
            <ServicesCard icon={<BlogIcon/>} title={'SEO and Content Strategy'} description={"Elevating your online presence with effective SEO tactics and compelling content to increase engagement and reach."} />
            <ServicesCard icon={<UxIcon/>} title={'UX Optimization'} description={"Optimizing user experience with intuitive and engaging design, enhancing user interaction and satisfaction."} />
            <ServicesCard icon={<AccessibilityIcon/>} title={'Accessibility Compliance and Enhancement'} description={"Ensuring your website is accessible to all, improving user experience and meeting compliance standards."} />

            <div className='plus-more'> 
                <ServicesCard icon={<PlusIcon/>} title={'And more...'}
                description={'Our expertise extends beyond these services. Reach out to explore how we can cater to your specific needs.'} />
            </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
