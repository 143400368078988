import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from './../../api/axios';
import './../styles/AddBlog.css';

function AddBlog() {
    const [blogTitle, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const [metaDesc, setMetaDesc] = useState('');
    const [image, setImage] = useState(null);
    const [imageAlt, setImageAlt] = useState('');

    const navigate = useNavigate();
    const { title } = useParams();

    const edit = title;
    const formTitle = edit ? "Edit Blog" : "Add New Blog";

    useEffect(() => {
        if (title) {
            axios.get(`/api/get-blog/${encodeURIComponent(title)}`)
                .then(response => {
                    const data = response.data;
                    setTitle(data.title);
                    setAuthor(data.author);
                    setContent(data.content);
                    setTags(data.tags ? data.tags.split(',') : []);
                    setCategories(data.categories ? data.categories.split(',') : []);
                    setMetaDesc(data.metaDesc || '');
                    setImageAlt(data.imageAlt || '');
                })
                .catch(error => console.error('Error fetching blog:', error));
        }
    }, [title]);

    // Function to add target="_blank" to all links in content
    const addTargetBlankToLinks = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const links = doc.querySelectorAll('a');
        
        links.forEach(link => {
            link.setAttribute('target', '_blank');
        });
        
        return doc.body.innerHTML;  // Return the modified HTML
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const currentDomain = window.location.hostname === 'localhost' ? 'localhost' : 'www.wattswebworks.com';
        let processedContent = content;

        // Add target="_blank" to links in the content
        processedContent = addTargetBlankToLinks(processedContent);

        // Check if URL replacement is needed
        if ((currentDomain === 'localhost' && (content.includes('https://www.wattswebworks.com') || content.includes('https://wattswebworks.com'))) ||
            (currentDomain === 'www.wattswebworks.com')) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/replace-urls`, {
                    content: processedContent,
                    currentDomain: currentDomain
                });
                processedContent = response.data.updatedContent;
            } catch (error) {
                console.error('Error replacing URLs:', error);
                return;
            }
        }
    
        const formData = new FormData();
        formData.append('title', blogTitle);
        formData.append('author', author);
        formData.append('content', processedContent);  // Use processed content here
        formData.append('tags', tags.join(','));
        formData.append('categories', categories.join(','));
        formData.append('metaDesc', metaDesc);
        formData.append('imageAlt', imageAlt);
        formData.append('type', 'blogs');
        if (image) {
            formData.append('image', image);
        }
    
        const url = title ? `${process.env.REACT_APP_API_URL}/api/update-blog/${encodeURIComponent(title)}` : `${process.env.REACT_APP_API_URL}/api/add-blog`;
        try {
            await axios({
                method: title ? 'PUT' : 'POST',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate('/admin/blogs');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };    

    const handleKeyPressTag = (e) => {
        if (e.key === ',') {
            e.preventDefault();
            const newTag = e.target.value.trim();
            if (newTag && !tags.includes(newTag)) {
                setTags(prev => [...prev, newTag]);
                e.target.value = '';
            }
        }
    };

    const handleKeyPressCategory = (e) => {
        if (e.key === ',') {
            e.preventDefault();
            const newCategory = e.target.value.trim();
            if (newCategory && !categories.includes(newCategory)) {
                setCategories(prev => [...prev, newCategory]);
                e.target.value = '';
            }
        }
    };

    const removeTag = indexToRemove => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    const removeCat = indexToRemove => {
        setCategories(categories.filter((_, index) => index !== indexToRemove));
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    return (
        <>
            <div className="formButtons">
                <Link to="/admin/blogs"><button className="cancelEdit">Cancel</button></Link>
            </div>
            <div className="add-blog-container">
                <div className="add-blog-form">
                    <h2>{formTitle}</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Title:</label>
                            <input
                                type="text"
                                value={blogTitle}
                                onChange={(e) => setTitle(e.target.value)}
                                maxLength={150}
                                required
                            />
                        </div>
                        <div>
                            <label>Author:</label>
                            <input
                                type="text"
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                                maxLength={75}
                                required
                            />
                        </div>
                        <div className="ck-editor">
                            <label>Content:</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={content}
                                config={{
                                    ckfinder: {
                                        uploadUrl: `${process.env.REACT_APP_API_URL}/api/upload-ckeditor-image?type=blogs`
                                    },
                                    contentsCss: 'body {max-width: 100%; overflow-wrap: break-word;}'
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                }}
                            />
                        </div>
                        <div>
                            <label>Image:</label>
                            <input
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                            />
                        </div>
                        <div>
                            <label>Image Alt Text:</label>
                            <input
                                type="text"
                                value={imageAlt}
                                onChange={(e) => setImageAlt(e.target.value)}
                                placeholder="Enter alt text for the image"
                                maxLength={150}
                            />
                        </div>
                        <div>
                            <label>Tags</label>
                            <input
                                type="text"
                                onKeyPress={handleKeyPressTag}
                                placeholder="Enter tags and press comma"
                            />
                            <div className="tags-container">
                                {tags.map((tag, index) => (
                                    <div key={index} className="tag">
                                        {tag}
                                        <button onClick={() => removeTag(index)}>X</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>Categories:</label>
                            <input
                                type="text"
                                onKeyPress={handleKeyPressCategory}
                                placeholder="Enter categories separated by a comma"
                            />
                            <div className="tags-container">
                                {categories.map((category, index) => (
                                    <div key={index} className="tag">
                                        {category}
                                        <button onClick={() => removeCat(index)}>X</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label>Page Meta Description:</label>
                            <input
                                type="text"
                                value={metaDesc}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                maxLength={150}
                                required
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AddBlog;
