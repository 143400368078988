import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './styles/NavBar.css';
import Logo from './../assets/icons/Logo';
import { useAuth } from './../context/AuthProvider';
import { ThemeContext } from './../App';


const NavBar = ({ setIsMenuVisible, isMenuVisible }) => {
  const { auth } = useAuth();
  const location = useLocation();

  // State hooks for managing navbar behaviors and appearance
  const [logoMouseDown, setLogoMouseDown] = useState(false);
  const [logoHovered, setLogoHovered] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 911);
  const { theme, toggleTheme } = useContext(ThemeContext); // Use theme from the context

  const [navStyles, setNavStyles] = useState({
    backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Initial background color based on theme
    backdropFilter: 'none',
  });

  // Function to handle key presses
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      toggleMenu();
    }
  };

    // Function to handle keyboard interaction for theme toggle
  const handleThemeToggle = (e) => {
      // Check for Enter (key code 13) or Space (key code 32) keys
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault(); // Prevent default behavior (scrolling for spacebar)
        toggleTheme(); // Toggle theme
      }
    };

  // Function to toggle the menu and handle animations
  const toggleMenu = () => {
    const newMenuVisibleState = !isMenuVisible;

    // Toggle visibility of the menu
    setIsMenuVisible(newMenuVisibleState);

    // Toggle the menu-open class on the app wrapper
    const appWrapper = document.querySelector('.AppWrapper');
    if (appWrapper) {
      appWrapper.classList.toggle('menu-open', newMenuVisibleState);
    }

    if (newMenuVisibleState) {
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        navbar.classList.remove('animate-slide-down');
      }
    }

    // When closing the menu, delay the blur effect for the fixed navbar
    if (!newMenuVisibleState) {
      const navbar = document.querySelector('.navbar-fixed');
      if (navbar) {
        setTimeout(() => {
          setNavStyles((prevStyles) => ({
            ...prevStyles,
            backdropFilter: 'blur(25px)', // Apply blur effect after delay
          }));
          navbar.classList.add('animate-slide-down');
          setTimeout(() => {
            navbar.classList.remove('animate-slide-down');
          }, 550);
        }, 250);
      }

      if (isFixed) {
        // Immediately apply background color, but delay blur effect
        setNavStyles((prevStyles) => ({
          ...prevStyles,
          backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Background based on theme
        }));

        setTimeout(() => {
          setNavStyles((prevStyles) => ({
            ...prevStyles,
            backdropFilter: 'blur(25px)', // Apply blur effect after delay
          }));
        }, 300);
      }
    } else {
      // Instantly reset the navbar styles (background based on theme, no blur) when opening the menu
      setNavStyles({
        backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Reset based on theme
        backdropFilter: 'none', // Reset blur instantly when opening
      });
    }
  };

// Effect to handle scroll and resize events, updating the fixed and visibility state of the navbar
useEffect(() => {
  const handleScroll = () => {
    const shouldBeFixed = window.scrollY > 400;
    const isMobile = window.innerWidth <= 775;

    if (isMobile && shouldBeFixed && !isFixed) {
      // Make the navbar fixed

              // Delay setting the navbar as fixed
        setTimeout(() => {
          setIsFixed(true);  // Set fixed after the delay

          // Then delay the visibility to trigger the slide-down effect
          if(isFixed && !isMenuVisible){
            setTimeout(() => {
              const navbar = document.querySelector('.navbar');
              if (navbar) {
                navbar.classList.add('animate-slide-down');
              }
            }, 350); // Delay before triggering the slide-down effect
          }


        }, 320); // 250ms delay before setting the navbar as fixed

        

      // Apply background color immediately only if the menu is closed
      if (!isMenuVisible) {
        setNavStyles((prevStyles) => ({
          ...prevStyles,
          backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Set based on theme
          backdropFilter: 'blur(20px)', // Apply blur immediately on scroll
        }));

        // Delay setting isVisible to true for smoother animation
        setTimeout(() => {
          const navbar = document.querySelector('.navbar');
          if (navbar) {
            navbar.classList.add('animate-slide-down');
          }
        }, 310); // Delay before triggering slide-down
      }
    } else if (!shouldBeFixed && isFixed) {
      // Remove fixed navbar when scrolling back up
      setIsFixed(false);

      // Remove the animation class when scrolling up
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        navbar.classList.remove('animate-slide-down');
      }

      // Reset navbar styles
      setNavStyles({
        backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Reset based on theme
        backdropFilter: 'none', // Remove blur instantly
      });
    }
  };

  handleScroll(); // Initial check when component mounts

  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleScroll);
  };
}, [isFixed, isMenuVisible, theme]); // Depend on both isFixed, isMenuVisible, and theme


  // Effect to update navbar styles when fixed and menu is closed
  useEffect(() => {
    if (isFixed && !isMenuVisible) {
      // Delay the blur effect by 300ms when the menu is closed
      setNavStyles({
        backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Background color based on theme
        backdropFilter: 'none',
      });

      // Apply the blur effect after 300ms
      setTimeout(() => {
        setNavStyles((prevStyles) => ({
          ...prevStyles,
          backdropFilter: 'blur(20px)', // Add blur effect
        }));
      }, 400);
    } else {
      setNavStyles({
        backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // White or dark background
        backdropFilter: 'none', // Remove blur instantly
      });
    }
  }, [isFixed, isMenuVisible, theme]); // Include theme in dependencies

  // Effect to handle screen resize and menu visibility on larger screens
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 911);

      if (window.innerWidth > 911) {
        setIsMenuVisible(false); // Close menu on larger screens

        // Reset the navbar styles and remove the fixed state if moving to a large screen
        setIsFixed(false); // Remove the fixed navbar when resizing to large screens
        setNavStyles({
          backgroundColor: theme === 'dark' ? 'rgb(1, 0, 29)' : 'rgba(255, 255, 255, 1)', // Reset based on theme
          backdropFilter: 'none', // Remove blur effect
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuVisible, setIsMenuVisible, theme]); // Include theme in dependencies

  // Smooth scroll to top on location change
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  // Constants for controlling line and plug display based on current path
  const [key] = useState(0);
  const pathsToShowLineAndPlug = ['/', '/about', '/services', '/blog'];
  const shouldShowLineAndPlug = pathsToShowLineAndPlug.includes(location.pathname);

  return (
    <>
      {isFixed && <div className="navbar-placeholder"></div>}
      <nav className={`navbar ${isFixed ? 'navbar-fixed' : ''}`} aria-label="Main navigation">
        <div className="navBarWrapper" style={isFixed ? navStyles : {}}>
          <a className="skip-to-content-link" href="#main-content">Skip to content</a>
          <div className="smallScreenWrapper">
            <div
              className="logo-svg"
              onMouseEnter={() => setLogoHovered(true)}
              onMouseLeave={() => setLogoHovered(false)}
              onMouseDown={() => setLogoMouseDown(true)}
              onMouseUp={() => setLogoMouseDown(false)}
            >
              <Link to="/">
                <Logo
                  scaleMultiplier={1}
                  bulbColor={
                    theme === 'dark'
                      ? (logoHovered ? "#e4a906" : "#e4a906")  // Yellow in dark mode
                      : (logoHovered ? "#e4a906" : "#022852")  // Blue in light mode
                  }
                  networkColor={logoHovered ? "#e4a906" : "#022852"}
                  lumensTransform={
                    logoMouseDown ? "scale(0.77) translate(-21.1rem, -10rem)" :
                    logoHovered ? "scale(0.84) translate(-22.6rem, -11.4rem)" :
                    "scale(0.77) translate(-21rem, -9.8rem)"
                  }
                  wwwColor={"#e4a906"}
                  shouldAnimate={true}
                />
              </Link>
            </div>

            <div className="hamburger-container">
              <div
                className="hamburger-toggle"
                aria-label="Toggle menu"
                aria-expanded={isMenuVisible}
                aria-controls="main-menu"
                role="button"
                aria-pressed={isMenuVisible}
                onClick={toggleMenu}
                onKeyDown={(e) => handleKeyDown(e)}  // Listen for the "Enter" key press
                tabIndex={0} // This makes it focusable via tab
              >
                <span className={`line top ${isMenuVisible ? 'open' : ''}`}></span>
                <span className={`line middle ${isMenuVisible ? 'open' : ''}`}></span>
                <span className={`line bottom ${isMenuVisible ? 'open' : ''}`}></span>
              </div>
            </div>

          </div>

          <div className="navlink-container">
            <div 
              className="navLinkWrapper" 
              id="main-menu"
              aria-hidden={isSmallScreen && !isMenuVisible}
            >
              {isSmallScreen && (
                <div className="opened-hamburger-container">
                  <div
                    className="hamburger-toggle"
                    aria-label="Toggle menu"
                    aria-expanded={isMenuVisible}
                    aria-controls="main-menu"
                    role="button"
                    aria-pressed={isMenuVisible}
                    onClick={toggleMenu}
                  >
                    <span className={`line top ${isMenuVisible ? 'open' : ''}`}></span>
                    <span className={`line middle ${isMenuVisible ? 'open' : ''}`}></span>
                    <span className={`line bottom ${isMenuVisible ? 'open' : ''}`}></span>
                  </div>
                </div>
              )}

              {isSmallScreen && (
                <div className="logo-svg"
                  onMouseEnter={() => setLogoHovered(true)}
                  onMouseLeave={() => setLogoHovered(false)}
                  onMouseDown={() => setLogoMouseDown(true)}
                  onMouseUp={() => setLogoMouseDown(false)}
                >
                  <Link to="/" tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}>
                    <Logo
                      scaleMultiplier={1}
                      bulbColor={
                        theme === 'dark'
                          ? (logoHovered ? "#e4a906" : "#e4a906")  // Yellow in dark mode
                          : (logoHovered ? "#e4a906" : "#022852")  // Blue in light mode
                      }
                      networkColor={logoHovered ? "#e4a906" : "#022852"}
                      lumensTransform={
                        logoMouseDown ? "scale(0.77) translate(-21.1rem, -10rem)" :
                        logoHovered ? "scale(0.84) translate(-22.6rem, -11.4rem)" :
                        "scale(0.77) translate(-21rem, -9.8rem)"
                      }
                      wwwColor={"#e4a906"}
                      shouldAnimate={true}
                    />
                  </Link>
                </div>
              )}
              {isSmallScreen && (
                <div className="theme-toggle-wrapper">
                  <input
                    type="checkbox"
                    id="light-switch"
                    onChange={toggleTheme} // Hook to toggle between light/dark mode
                    checked={theme === 'dark'} // Checked if the theme is dark
                    onKeyDown={handleThemeToggle}
                  />
                  <label htmlFor="light-switch" id="light-switch-label">
                    <div className="switch"></div>
                  </label>
                </div>
              )}
              <div className="navLinks">
                <NavLink 
                  to="/" 
                  activeclassname="active" 
                  aria-current={location.pathname === '/' ? 'page' : undefined} 
                  onClick={() => isMenuVisible && toggleMenu()} 
                  tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}
                >
                  <span>
                    <div className="active-indicator" />
                    Home
                  </span>
                  {isMenuVisible && location.pathname !== '/' && <i className="fa-solid fa-chevron-right" aria-hidden="true" />}
                </NavLink>
                <NavLink 
                  to="/about" 
                  activeclassname="active" 
                  aria-current={location.pathname === '/about' ? 'page' : undefined} 
                  onClick={() => isMenuVisible && toggleMenu()} 
                  tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}
                >
                  <span>
                    <div className="active-indicator" />
                    About Us
                  </span>
                  {isMenuVisible && location.pathname !== '/about' && <i className="fa-solid fa-chevron-right" aria-hidden="true" />}
                </NavLink>
                <NavLink 
                  to="/services" 
                  activeclassname="active" 
                  aria-current={location.pathname === '/services' ? 'page' : undefined} 
                  onClick={() => isMenuVisible && toggleMenu()} 
                  tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}
                >
                  <span>
                    <div className="active-indicator" />
                    Our Services
                  </span>
                  {isMenuVisible && location.pathname !== '/services' && <i className="fa-solid fa-chevron-right" aria-hidden="true" />}
                </NavLink>
                {isSmallScreen && (
                  <NavLink 
                    to="/blog" 
                    activeclassname="active" 
                    aria-current={location.pathname === '/blog' ? 'page' : undefined} 
                    onClick={() => isMenuVisible && toggleMenu()} 
                    tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}
                  >
                    <span>
                      <div className="active-indicator" />
                      Blog
                    </span>
                    {isMenuVisible && location.pathname !== '/blog' && <i className="fa-solid fa-chevron-right" aria-hidden="true" />}
                  </NavLink>
                )}
                <HashLink 
                  smooth 
                  to="/about#contact" 
                  onClick={() => isMenuVisible && toggleMenu()} 
                  aria-current={location.pathname === '/about#contact' ? 'page' : undefined}
                  tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0} // Ensure it's not focusable when menu is closed
                  inert={isSmallScreen && !isMenuVisible ? "true" : undefined} // Prevent interaction when menu is closed
                >
                  <span>
                    <div className="active-indicator" />
                    Contact Us
                  </span>
                  {isMenuVisible && location.hash !== '#contact' && <i className="fa-solid fa-chevron-right" aria-hidden="true" />}
                </HashLink>
                {auth.isAuthenticated && (
                  <NavLink 
                    to="/admin/dashboard" 
                    activeclassname="active" 
                    aria-current={location.pathname === '/admin/dashboard' ? 'page' : undefined} 
                    onClick={() => isMenuVisible && toggleMenu()} 
                    tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}
                  >
                    <span>
                      <div className="active-indicator" />
                      Admin
                    </span>
                    {isMenuVisible && location.pathname !== '/admin/dashboard' && <i className="fa-solid fa-chevron-right" aria-hidden="true" />}
                  </NavLink>
                )}
              </div>
              {!isSmallScreen && (
                <div className="navLinkBlog">
                  <NavLink 
                    to="/blog" 
                    activeclassname="active" 
                    aria-current={location.pathname === '/blog' ? 'page' : undefined} 
                    onClick={() => isMenuVisible && toggleMenu()}
                  >
                    <span>
                      <div className="active-indicator" />
                      Blog
                    </span>
                    <i className="fa-regular fa-comments" aria-hidden="true"/>
                  </NavLink>
                </div>
              )}
              {isSmallScreen && (
                <div className="navbar-consultation">
                  <HashLink
                    smooth
                    to="/about#contact"
                    onClick={() => isMenuVisible && toggleMenu()}
                    aria-current={location.pathname === '/about#contact' ? 'page' : undefined}
                    tabIndex={isSmallScreen && !isMenuVisible ? -1 : 0}  // Ensure it's not focusable when menu is closed
                    inert={!isMenuVisible ? "true" : undefined}  // Prevent interaction when menu is closed
                  >
                    👋 Book a FREE Consultation
                  </HashLink>
                </div>
              )}
            </div>

          </div>

          {shouldShowLineAndPlug && (
            <div key={key} className="navbar-yellow-line">
              <i className="fa-solid fa-plug" aria-hidden="true"></i>
            </div>
          )}
        </div>
      </nav>
    </>
  );

};

export default NavBar;
