import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/HeroCarousel.css';

const HeroCarousel = ({ slides, loading }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
    const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);
    const [focusedButtonIndex, setFocusedButtonIndex] = useState(null); // New state for tracking focus
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    // Update isSmallScreen on window resize
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 500);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Settings for the carousel slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        beforeChange: (current, next) => handleSlideChange(next),
        afterChange: (current) => setCurrentSlideIndex(current),
    };

    // Handle slide change for background color
    const handleSlideChange = useCallback((nextIndex) => {
        const slickSlides = document.querySelectorAll('.slick-slide');
        slickSlides.forEach((slideElement, i) => {
            const slide = slides[i % slides.length];
            if (slide) {
                slideElement.style.backgroundColor = slide.baseColor;
                slideElement.style.visibility = 'visible';
            }
        });
    }, [slides]);

    useEffect(() => {
        handleSlideChange(0);
    }, [handleSlideChange]);

    // Memoize background and foreground styling
    const getBackgroundStyle = useCallback((slide) => {
        return slide.slideType === 'solid-color'
            ? { backgroundColor: slide.color }
            : { background: slide.gradient };
    }, []);

    const parseForegroundStyling = useCallback((stylingString, scale = 1) => {
        if (!stylingString || stylingString === 'none') return {};

        const toCamelCase = (str) => str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
        const scaleStyleValue = (value) => {
            const number = parseFloat(value);
            const unit = value.replace(number, '');
            return (number / scale) + unit;
        };

        return stylingString.split(';').reduce((styles, style) => {
            const [property, value] = style.split(':').map(str => str.trim());
            if (property && value) {
                styles[toCamelCase(property)] = scale === 1 ? value : scaleStyleValue(value);
            }
            return styles;
        }, {});
    }, []);

    const parseButtonStyling = useCallback((stylingString) => {
        if (!stylingString || stylingString === 'none') return {};

        const toCamelCase = (str) => str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
        return stylingString.split(';').reduce((styles, style) => {
            const [property, value] = style.split(':').map(str => str.trim());
            if (property && value) {
                styles[toCamelCase(property)] = value;
            }
            return styles;
        }, {});
    }, []);

    // Consolidate button styles
    const getButtonStyles = useMemo(() => slides.map((slide) => {
        const sharedStyles = {
            backgroundColor: slide.buttonColor,
            color: slide.buttonTextColor,
            ...parseButtonStyling(slide.buttonAdditionalStyling),
        };

        const hoverFocusStyles = {
            backgroundColor: slide.buttonTextColor,
            color: slide.buttonColor,
            ...parseButtonStyling(slide.buttonAdditionalStyling),
        };

        if (slide.buttonGlowColor && slide.buttonGlowColor !== 'none') {
            const glowColor = slide.buttonGlowColor;
            sharedStyles.boxShadow = `0 0 5px ${glowColor}, 0 0 25px ${glowColor}, 0 0 100px ${glowColor}`;
            hoverFocusStyles.boxShadow = `0 0 5px ${glowColor}, 0 0 25px ${glowColor}, 0 0 100px ${glowColor}`;
        }

        return { baseButtonStyles: sharedStyles, hoverButtonStyles: hoverFocusStyles };
    }), [slides, parseButtonStyling]);

    // Render a skeleton when loading or when no slides are available
    if (loading || !slides || slides.length === 0) {
        const skeletonHeight = isSmallScreen ? '55vh' : '77vh';
        return (
            <div className="hero-carousel">
                <Skeleton height={skeletonHeight} width="100%" style={{ '--animation-duration': '4s' }}/>
            </div>
        );
    }

    return (
        <div className="hero-carousel">
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div 
                        key={index} 
                        className="slide"
                        aria-hidden={currentSlideIndex !== index}
                        tabIndex={currentSlideIndex === index ? 0 : -1}
                    >
                        <div
                            className="slide-background"
                            style={{
                                ...getBackgroundStyle(slide),
                                ...parseForegroundStyling(slide.foregroundStyling, isSmallScreen ? 3 : 1),
                            }}
                        >
                            {slide.slideType === 'image' && (
                                <img 
                                    src={slide.image} 
                                    alt="Slide" 
                                    className="slide-image" 
                                    onError={(e) => e.target.style.display = 'none'}
                                />
                            )}
                        </div>
                        {slide.content && (
                            <div className={`slide-content ${slide.slideAlign || 'left'}`}>
                                <h1>{slide.caption}</h1>
                                <p>{slide.description}</p>
                                {slide.buttonLink && (
                                    slide.buttonLink.includes('#') ? (
                                        <HashLink
                                            to={slide.buttonLink}
                                            className="slide-button"
                                            style={
                                                hoveredButtonIndex === index || focusedButtonIndex === index
                                                    ? getButtonStyles[index].hoverButtonStyles
                                                    : getButtonStyles[index].baseButtonStyles
                                            }
                                            onMouseEnter={() => setHoveredButtonIndex(index)}
                                            onMouseLeave={() => setHoveredButtonIndex(null)}
                                            onFocus={() => setFocusedButtonIndex(index)} // Handle focus
                                            onBlur={() => setFocusedButtonIndex(null)} // Handle blur
                                            tabIndex={currentSlideIndex === index ? 0 : -1}
                                            aria-hidden={currentSlideIndex !== index}
                                        >
                                            {slide.buttonText}
                                        </HashLink>
                                    ) : (
                                        <Link
                                            to={slide.buttonLink}
                                            className="slide-button"
                                            style={
                                                hoveredButtonIndex === index || focusedButtonIndex === index
                                                    ? getButtonStyles[index].hoverButtonStyles
                                                    : getButtonStyles[index].baseButtonStyles
                                            }
                                            onMouseEnter={() => setHoveredButtonIndex(index)}
                                            onMouseLeave={() => setHoveredButtonIndex(null)}
                                            onFocus={() => setFocusedButtonIndex(index)} // Handle focus
                                            onBlur={() => setFocusedButtonIndex(null)} // Handle blur
                                            tabIndex={currentSlideIndex === index ? 0 : -1}
                                            aria-hidden={currentSlideIndex !== index}
                                        >
                                            {slide.buttonText}
                                        </Link>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default HeroCarousel;
