import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './../pages/styles/Blog.css';
import BlogCard from '../components/BlogCard';
import axios from './../api/axios';
import { Helmet } from 'react-helmet-async';

export default function BlogTagView() {
  const [blogPostsData, setBlogPostsData] = useState([]);
  const { tag } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPostsByTag = async () => {
      try {
        const response = await axios.get(`/api/get-blogs/tag/${encodeURIComponent(tag)}`);
        setBlogPostsData(response.data);
        setError(null);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error("Failed to fetch blog posts by tag:", error);
        }
        if (error.response && error.response.status === 404) {
          setError('No blogs found for this tag.');
        } else {
          setError('Something went wrong. Please try again later.');
        }
      }
      setLoading(false);
    };

    fetchBlogPostsByTag();
  }, [tag]);

  const decodedTag = decodeURIComponent(tag);

  return (
    <div className='blog-tag-view'>
      <Helmet>
        <title>Tag: {decodedTag} - WattsWebWorks Blog</title>
        <meta name="description" content={`Blogs tagged under ${decodedTag}`} />
        <meta property="og:title" content={`Tag: ${decodedTag} - WattsWebWorks Blog`} />
        <meta property="og:description" content={`Explore blogs tagged under ${decodedTag} on WattsWebWorks`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={`https://www.wattswebworks.com/blog/tag/${encodeURIComponent(tag)}`} />
      </Helmet>

      {/* Main heading for the page */}
      <h1 className='blogs-tag-title'>
        Tag:&nbsp;<span className='tagName'>{decodedTag}</span>
      </h1>

      {/* Link to view all tags */}
      <h2 className='all-categories-link'>
        <Link to="/blog">View All Tags</Link>
      </h2>

      {/* Result message */}
      <p className='result-message'>
        {error ? <span>{error}</span> : `${blogPostsData.length} ${blogPostsData.length === 1 ? 'Result' : 'Results'}`}
      </p>

      {/* Blog posts */}
      <div className='blogs'>
        {(loading || blogPostsData.length === 0) ? (
          // Render skeleton loaders if loading or no blog posts
          Array.from({ length: 2 }).map((_, index) => (
            <BlogCard key={index} loading={true} isFeatured={index === 0} />
          ))
        ) : (
          blogPostsData.map((post) => (
            <BlogCard key={post.id} {...post} />
          ))
        )}
      </div>
    </div>
  );
}
