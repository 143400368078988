import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import axios from './../../api/axios';
import './../styles/AdminDashboard.css';
import AdminHeader from './AdminHeader';

const AdminDashboard = () => {
    const { auth } = useAuth();
    const [roleConfig, setRoleConfig] = useState({});
    const [configLoading, setConfigLoading] = useState(true);
    const [sitemapStatus, setSitemapStatus] = useState(null);
    const [inquiryCount, setInquiryCount] = useState(0);
    const [blogsNotInSitemapCount, setBlogsNotInSitemapCount] = useState(0);
    const [allBlogsInSitemap, setAllBlogsInSitemap] = useState(false);
    const [sessionCount, setSessionCount] = useState(0); // New state for session count

    useEffect(() => {
        const fetchRoleConfig = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-route-config`);
                const config = response.data.reduce((acc, { route, roles }) => {
                    acc[route] = roles.split(',');
                    return acc;
                }, {});
                setRoleConfig(config);
                setConfigLoading(false);
            } catch (error) {
                console.error('Failed to fetch role configuration:', error);
            }
        };

        fetchRoleConfig();
    }, []);

    const checkAccess = useCallback((path) => {
        return roleConfig[path]?.includes(auth.user?.role);
    }, [roleConfig, auth.user?.role]);

    const parseSitemap = useCallback((sitemapXml) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(sitemapXml, 'text/xml');
        const urlElements = xmlDoc.getElementsByTagName('loc');
        const urls = Array.from(urlElements).map(urlElement => urlElement.textContent);
        return urls;
    }, []);

    const normalizeTitle = useCallback((title) => {
        return encodeURIComponent(title.trim()).replace(/%20/g, '%20');
    }, []);

    const isBlogInSitemap = useCallback((blogTitle, sitemapUrls) => {
        const normalizedTitle = normalizeTitle(blogTitle);
        const blogUrl = `https://www.wattswebworks.com/blog/${normalizedTitle}`;
        return sitemapUrls.includes(blogUrl);
    }, [normalizeTitle]);

    useEffect(() => {
        const fetchInquiryCount = async () => {
            if (checkAccess("/admin/inquiries")) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-inquiry-timeslots`);
                    setInquiryCount(response.data.length);
                } catch (error) {
                    console.error('Error fetching inquiry count:', error);
                }
            }
        };

        const fetchBlogsAndCheckSitemap = async () => {
            if (checkAccess("/admin/blogs")) {
                try {
                    const blogResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-blogs`);
                    const blogs = blogResponse.data;

                    const sitemapResponse = await axios.get(`${window.location.origin}/sitemap.xml`, { responseType: 'text' });
                    const sitemapUrls = parseSitemap(sitemapResponse.data);

                    const blogsNotInSitemap = blogs.filter(blog => !isBlogInSitemap(blog.title, sitemapUrls));
                    setBlogsNotInSitemapCount(blogsNotInSitemap.length);
                    setAllBlogsInSitemap(blogsNotInSitemap.length === 0);
                } catch (error) {
                    console.error('Error fetching blogs or checking sitemap:', error);
                }
            }
        };

        const fetchSessionCount = async () => {
            if (checkAccess("/admin/users")) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-num-sessions`);
                    console.log(response.data)
                    setSessionCount(response.data.count);
                } catch (error) {
                    console.error('Error fetching session count:', error);
                }
            }
        };

        fetchInquiryCount();
        fetchBlogsAndCheckSitemap();
        fetchSessionCount(); // Fetch session count
    }, [checkAccess, isBlogInSitemap, parseSitemap]);

    const handleGenerateSitemap = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-sitemap`);
            if (response.status === 200) {
                setSitemapStatus('Sitemap generated successfully.');
            } else {
                setSitemapStatus('Failed to generate sitemap.');
            }
        } catch (error) {
            console.error('Error generating sitemap:', error);
            setSitemapStatus('Error occurred while generating sitemap.');
        }
    };

    if (configLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <AdminHeader />
            <h1 className='admin-welcome'>Welcome <span className='dashboard-username'>{auth.user.username}</span>!</h1>
            <div className='sitemap-generation'>
                <h2>Generate Sitemap</h2>
                <button onClick={handleGenerateSitemap}>Generate Sitemap</button>
                {sitemapStatus && <p>{sitemapStatus}</p>} {/* Display status of sitemap generation */}
            </div>
            <div className='dashboard'>
                {checkAccess("/admin/inquiries") && (
                    <Link to='/admin/inquiries' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <div className='dashboard-big-icon'>
                                <i className="fa-regular fa-envelope"/>
                            </div>
                            <div className='dashboard-small-icon'>
                                <i className="fa-regular fa-envelope"/>
                            </div>
                            <div className='dashboard-label'>
                                <p>
                                    Inquiries 
                                </p>
                                {inquiryCount > 0 ? (
                                    <span className="badge">{inquiryCount}</span>
                                ) : (
                                    <span className="badge green">{inquiryCount}</span>
                                )}
                            </div>
                        </div>
                    </Link>
                )}
                {checkAccess("/admin/slides") && (
                    <Link to='/admin/slides' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <div className='dashboard-big-icon'>
                                <i className="fa-regular fa-images"/>
                            </div>
                            <div className='dashboard-small-icon'>
                                <i className="fa-regular fa-images"/>
                            </div>
                            <div className='dashboard-label'>
                                <p>Slides</p>
                            </div>
                        </div>
                    </Link>
                )}
                {checkAccess("/admin/blogs") && (
                    <Link to='/admin/blogs' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <div className='dashboard-big-icon'>
                                <i className="fa-regular fa-newspaper"/>
                            </div>
                            <div className='dashboard-small-icon'>
                                <i className="fa-regular fa-newspaper"/>
                            </div>
                            <div className='dashboard-label'>
                                <p>
                                    Blogs
                                </p>
                                {allBlogsInSitemap ? (
                                    <span className="sitemap-badge-green">All in sitemap</span>
                                ) : (
                                    <span className="sitemap-badge-red">{blogsNotInSitemapCount} not in sitemap, please generate</span>
                                )}
                            </div>
                        </div>
                    </Link>
                )}
                {checkAccess("/admin/users") && (
                    <Link to='/admin/users' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <div className='dashboard-big-icon'>
                                <i className="fa-solid fa-users"/>
                            </div>
                            <div className='dashboard-small-icon'>
                                <i className="fa-solid fa-users"/>
                            </div>
                            <div className='dashboard-label'>
                                <p>Users and Roles</p>
                                {sessionCount > 0 && (
                                    <span className={sessionCount === 1 ? "users-badge-one" : "users-badge-multi"}>
                                        {sessionCount} logged in
                                    </span>
                                )}
                            </div>
                        </div>
                    </Link>
                )}
            </div>
        </>
    );
}

export default AdminDashboard;
