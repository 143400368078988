import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import ContactBar from './components/ContactBar';
import PrivateRoute from './components/PrivateRoute';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Footer from './components/Footer';
import Blog from './pages/Blog';
import BlogView from './pages/BlogView';

import Admin from './pages/Admin';
import AddBlog from './components/admin/AddBlog';
import AddUser from './components/admin/AddUser';
import Login from './components/Login';
import AddSlide from './components/admin/AddSlide';
import BlogCategoryView from './components/BlogCategoryView';
import BlogTagView from './components/BlogTagView';
import UsersAdmin from './components/admin/UsersAdmin';
import BlogsAdmin from './components/admin/BlogsAdmin';
import SlidesAdmin from './components/admin/SlidesAdmin';
import NotFoundPage from './pages/NotFoundPage';

import webDesignImage from './assets/images/webDesignNew.png';
import missionImage from './assets/images/multipleWebsites.png';

import countryRoadsImage from './assets/images/countryRoads.jpg';
import servicesBG from './assets/images/servicesBG.jpg';
import InquiriesAdmin from './components/admin/InquiriesAdmin';
import AdminDashboard from './components/admin/AdminDashboard';
import Sitemap from './pages/Sitemap';

export const ThemeContext = createContext(null);

function App() {
  const location = useLocation();
  const [theme, setTheme] = useState('light'); // State to track theme
  
  // State to track menu visibility
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  // Determine if the footer should be shown based on the current path
  const showFooter = ['/', '/about', '/services'].includes(location.pathname) || location.pathname.startsWith('/blog');

  const toggleTheme = () => {
    setTheme((curr) => (curr === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const imagesToPreload = [
      countryRoadsImage,
      servicesBG,
      webDesignImage,
      missionImage,
    ];
    imagesToPreload.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div id={theme} className={`AppWrapper ${isMenuVisible ? 'menu-open' : ''}`}>
        <header role="banner">
          <ContactBar />
          <NavBar setIsMenuVisible={setIsMenuVisible} isMenuVisible={isMenuVisible} />
        </header>

        <main id="main-content" className="content-wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/category/:category" element={<BlogCategoryView />} />
            <Route path="/blog/tag/:tag" element={<BlogTagView />} />
            <Route path="/blog/:title" element={<BlogView />} />
            <Route path="/sitemap" element={<Sitemap />} />

            <Route path="/login" element={<Login />} />

            <Route element={<PrivateRoute />}>
              <Route path="/admin" element={<Admin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="slides" element={<SlidesAdmin />} />
                <Route path="slides/new-slide" element={<AddSlide />} />
                <Route path="slides/edit-slide/:id" element={<AddSlide />} />
                <Route path="blogs" element={<BlogsAdmin />} />
                <Route path="blogs/new-blog" element={<AddBlog />} />
                <Route path="blogs/edit-blog/:title" element={<AddBlog />} />
                <Route path="inquiries" element={<InquiriesAdmin />} />
                <Route path="users" element={<UsersAdmin />} />
                <Route path="users/new-user" element={<AddUser />} />
                <Route path="users/edit-user/:id" element={<AddUser />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        {showFooter && <Footer />}
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
