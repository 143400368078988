import React, { useEffect, useState, useRef, useContext } from 'react';
import './styles/Home.css';
import HeroCarousel from '../components/HeroCarousel'; // Import HeroCarousel
import Services from '../components/Services';
import Benefits from '../components/Benefits';
import axios from './../api/axios';
import { Helmet } from 'react-helmet-async';
import { ThemeContext } from './../App';

import Logo from '../assets/icons/Logo';

export default function Home() {
    const [slides, setSlides] = useState([]);
    const [mounted, setMounted] = useState(false);
    const aboutSectionRef = useRef(null);
    const [loading, setLoading] = useState(true);

    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        const fetchSlides = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/api/get-slides');
                const slidesData = response.data.map(slide => ({
                    ...slide,
                    image: slide.image ? `${process.env.REACT_APP_API_URL}/uploads/slides/${slide.image}` : null,
                }));
                setTimeout(() => {
                    setSlides(slidesData);
                    setLoading(false);
                }, 30);
            } catch (error) {
                // Optionally log the error to a service instead of the console in production
                // console.error("Failed to fetch slides:", error);
                setLoading(false);
            }
        };
        fetchSlides();
    }, []);

    useEffect(() => {
        setMounted(true); // Indicate that the component has mounted
    }, []);

    useEffect(() => {
        if (!mounted) return; // Ensure the component is mounted before running the observer logic
    
        const aboutItems = document.querySelectorAll('.about-logo, .about-content');
        
        aboutItems.forEach((item, index) => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const delay = index * 90; // Delay based on index
                        setTimeout(() => {
                            entry.target.classList.add('slide-up');
                            observer.unobserve(entry.target); // Unobserve after first intersection
                        }, delay);
                    }
                });
            }, { threshold: 0.18 });
    
            observer.observe(item);
        });
    
        return () => {
            aboutItems.forEach((item) => {
                const observer = new IntersectionObserver(() => {});
                observer.unobserve(item);
                observer.disconnect();
            });
        };
    }, [mounted]);
    
    
    

    const scrollToAboutSection = () => {
        if (aboutSectionRef.current) {
            const offset = 85;
            const top = aboutSectionRef.current.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    };
    return (
        <>
            <Helmet>
                <title>Home - WattsWebWorks West Virginia Web Design</title>
                <meta name="description" content="WattsWebWorks offers premier web design and digital services tailored for businesses in West Virginia, ensuring a strong online presence." />
                <meta property="og:title" content="Home - WattsWebWorks West Virginia Web Design" />
                <meta property="og:description" content="WattsWebWorks offers premier web design and digital services tailored for businesses in West Virginia, ensuring a strong online presence." />
                <meta property="og:url" content="https://www.wattswebworks.com" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <link rel="canonical" href="https://www.wattswebworks.com" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebSite",
                        "name": "WattsWebWorks",
                        "url": "https://www.wattswebworks.com",
                        "description": "WattsWebWorks specializes in creating dynamic, user-friendly websites and web applications to power your online presence.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "WattsWebWorks",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://www.wattswebworks.com/logo512.png"
                            }
                        }
                    })}
                </script>
            </Helmet>

            <div className="section-wrapper">

                <section className="hero persistent" aria-labelledby="hero-section">
                    <HeroCarousel slides={slides} loading={loading}/> 
                </section>

                <div className='section-separator'>
                    <div className="about-separator-step-1"></div>
                    <div className="about-separator-step-2"></div>
                    <div 
                        className="about-separator-step-3" 
                        onClick={scrollToAboutSection} 
                        tabIndex={0} // Makes the button focusable via tab key
                        role="button" // Ensures it's recognized as a button by screen readers
                        aria-label="Scroll to Learn More section" // Adds an accessible label for screen readers
                        onKeyDown={(e) => { if (e.key === 'Enter') scrollToAboutSection(); }} // Allows 'Enter' to trigger the scroll
                    >
                        <p>
                            <i className="fa-solid fa-angles-down"/><br/>
                            <span>Learn More</span>
                        </p>
                    </div>

                    <div className="about-separator-step-4"></div>
                    <div className="about-separator-step-5"></div>
                </div>

                <section className="about" aria-labelledby="about-us" ref={aboutSectionRef}>
                    <div className="about-container">
                        <div className='about-logo'>
                            <Logo
                                scaleMultiplier={4}
                                shouldAnimate={false}
                                lumensTransform={'scale(3.1) translate(-21.2rem, -10rem)'}
                                hideLumens={false}
                                bulbColor={theme === 'dark' ? "rgb(129, 134, 147)" : "#022852"}
                                networkColor={"#022852"}
                                cycleColors={true}
                                cycleInterval={1250}
                            />
                        </div>
                        <div className="about-content">
                            <h2>Why Choose Us?</h2>
                            <p>
                                At WattsWebWorks, our commitment to <span className="emphasis">illuminating</span> your online presence stands at the forefront of our services. 
                                Leveraging a blend of innovative design and cutting-edge technology, we craft websites that not only resonate with your audience but also help 
                                empower your business. Our passion for distinctive and impactful digital experiences drives us to achieve excellence in every project, 
                                meaning yours will be handled with precision and creativity, and will not only meet but exceed 
                                expectations. By choosing WattsWebWorks, you're opting for a partner who values your success as much as you do, providing a foundation for growth, visibility, and success. 
                            </p>
                        </div>
                    </div>
                </section>

                <div className='section-separator'>
                    <div className="benefits-separator-step-1"></div>
                    <div className="benefits-separator-step-2"></div>
                    <div className="benefits-separator-step-3"></div>
                    <div className="benefits-separator-step-4"></div>
                </div>


                <section className="benefits" aria-label='Our Comprehensive Approach to Optimizing Your Web Potential'>
                    <Benefits />
                </section>

                <div className='section-separator services-separator'>
                    <div className="services-separator-step-1"></div>
                    <div className="services-separator-step-2"></div>
                    <div className="services-separator-step-3"></div>
                    <div className="services-separator-step-4"></div>
                </div>

                <section className="services" aria-label='Our Services'>
                    <div className="overlay"></div>
                        <Services />
                </section>

            </div>
        </>
    );
}
