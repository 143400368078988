import React, { useState, useRef, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import './styles/Footer.css';
import FooterMap from './FooterMap';
import TosModal from './TosModal';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const modalTriggerRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Track screen size

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (modalTriggerRef.current) {
      modalTriggerRef.current.focus(); // Return focus to the trigger element
    }
  };

  // Handle keyboard accessibility
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleModal();
    }
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  // Update screen size for responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Track if screen is below 768px
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <footer className="footer" aria-labelledby="footer-heading">
      <div className="footer-cta">
        <div className="footer-cta-text">
          <h2 id="footer-heading">Looking to power up your online presence and streamline your digital strategy?</h2>
          <p>Contact us to discover how we can <span>illuminate</span> your path to digital success!</p>
        </div>
        <div className="footer-cta-button">
          <HashLink to="/about#contact" className='footer-button' aria-label="Get in Touch">Get in Touch</HashLink>
        </div>
      </div>

      <hr className="footer-divider" />
      <div className="footer-content">
        <div className="footer-column footer-contact">
          <h3 className="footer-heading">Location</h3>
          <p className="footer-phone"><a href="tel:304-936-2254" className="phone-number" aria-label="Call us at 304-936-2254">304-936-2254</a></p>
          <p className="footer-phone"><a href="mailto:contact@wattswebworks.com" aria-label="Email us at contact@wattswebworks.com" className="phone-number">contact@wattswebworks.com</a></p>
          <address>
            West Virginia
            <FooterMap />
          </address>
          <h3 className="footer-heading">Find More Here</h3>
          <div className='social-icons'>
            <a href="https://www.google.com/maps/place/WattsWebWorks" aria-label="Find us on Google" rel="noopener noreferrer" target="_blank">
              <i className="fa-brands fa-google" aria-hidden="true" />
            </a>
            <a href="https://www.facebook.com/WattsWebWorks" aria-label="Visit our Facebook page" rel="noopener noreferrer" target="_blank">
              <i className="fa-brands fa-square-facebook" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div className="footer-center-text">
          <p>Copyright <span>©</span> 2024 WattsWebWorks LLC</p>

          {/* Conditionally render "Back to Top" button in this section based on screen size */}
          {!isSmallScreen && (
            <div className='to-top-button-wrapper'>
              <div 
                  onClick={scrollToTop} 
                  className="back-to-top-button" 
                  aria-label="Back to Top" 
                  role='button'
                  tabIndex={0} // Ensure it's focusable on larger screens
                  onKeyDown={(e) => { if (e.key === 'Enter') scrollToTop(); }} // Trigger scroll on "Enter"
              >
                  <span>Back to top</span>
                  <i className="fa-solid fa-square-caret-up" />
                  <div className='bottom-arrow-keys'>
                      <i className="fa-solid fa-square-caret-left" />
                      <i className="fa-solid fa-square-caret-down" />
                      <i className="fa-solid fa-square-caret-right" />
                  </div>
              </div>
            </div>
          )}
        </div>

        <div className="footer-column footer-nav">
          <h3 className="footer-heading">Navigation</h3>
          <nav aria-label="Footer Navigation">
            <ul>
              <li><Link to="/about" onClick={scrollToTop} aria-label="Who We Are">Who We Are</Link></li>
              <li><Link to="/services" onClick={scrollToTop} aria-label="Our Services">Our Services</Link></li>
              <li><HashLink to="/about#contact" aria-label="Contact Us">Contact Us</HashLink></li>
              <li><Link to="/blog" onClick={scrollToTop} aria-label="Our Blog">Our Blog</Link></li>
              <li><Link to="/sitemap" aria-label="Sitemap">Sitemap</Link></li>
            </ul>
          </nav>

          <h3 className="footer-heading">Legal</h3>
          <ul>
            <li>
              <p
                ref={modalTriggerRef}
                onClick={toggleModal}
                onKeyDown={handleKeyDown}
                tabIndex="0"
                role="button"
                className='tos-modal-toggle'
                aria-label="Terms of Service & Privacy Policy"
              >
                Terms of Service & Privacy Policy
              </p>
            </li>
          </ul>
        </div>

        {/* On small screens, render "Back to Top" button here after navigation */}
        {isSmallScreen && (
          <div className='to-top-button-wrapper'>
              <div 
                  onClick={scrollToTop} 
                  className="back-to-top-button" 
                  aria-label="Back to Top" 
                  role='button'
                  tabIndex={0} // Ensure it's part of the tab order after the nav section on small screens
                  onKeyDown={(e) => { if (e.key === 'Enter') scrollToTop(); }} // Trigger scroll on "Enter"
              >
                  <span>Back to top</span>
                  <i className="fa-solid fa-square-caret-up" />
                  <div className='bottom-arrow-keys'>
                      <i className="fa-solid fa-square-caret-left" />
                      <i className="fa-solid fa-square-caret-down" />
                      <i className="fa-solid fa-square-caret-right" />
                  </div>
              </div>
          </div>
        )}

      </div>

      {showModal && <TosModal handleClose={handleCloseModal} />}
    </footer>
  );
};

export default Footer;
