import React from 'react';
import MoonLoader  from "react-spinners/MoonLoader";

const Loading = () => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 9999,
        }}>
            <MoonLoader  color="#e4a906" speedMultiplier={0.6}/>
        </div>
    );
}

export default Loading;
