import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/BlogCard.css';

const BlogCard = ({ id, title, modified, published, content, link, author, image, imageAlt, isFeatured, categories, description, loading }) => {

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const renderBlogPostCategories = () => {
        if (!categories) return null; // If no categories, return null

        const categoriesArray = categories.split(','); // Assuming categories is a comma-separated string

        return (
            <div className="blog-post-categories">
                {categoriesArray.map((category, index) => (
                    <span key={index} className="blog-category"><i className="fa-solid fa-list"></i> {category.trim()}</span>
                ))}
            </div>
        );
    };

    if (loading) {
        return (
            <div className={`blog-post ${isFeatured ? 'featured' : ''}`}>
                <Skeleton style={{ '--animation-duration': '4s' }} height={200} />
                <Skeleton style={{ '--animation-duration': '4s', marginTop: 10 }} height={30} />
                <Skeleton style={{ '--animation-duration': '4s', marginTop: 10, width: '80%' }} height={20} />
                <Skeleton style={{ '--animation-duration': '4s', marginTop: 10, width: '60%' }} height={20} />
                <Skeleton style={{ '--animation-duration': '4s', marginTop: 10, width: '40%' }} height={20} />
            </div>
        );
    }    

    return (
        <Link to={`/blog/${title}`} className={`blog-post ${isFeatured ? 'featured' : ''}`} aria-label={`Read blog post: ${title}`}>
            <div>
                {<img 
                    src={`${process.env.REACT_APP_API_URL}/uploads/blogs/${image}`} 
                    alt={imageAlt || "Blog image"} 
                    className="blog-image"
                    />
                }
                <h3>{title}</h3>
                <time>{formatDate(published)}</time>
                <p>{description}</p>
                <div className='blogcard-categories'>
                    {renderBlogPostCategories()}
                </div>
            </div>
        </Link>
    );
};

export default BlogCard;
