import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './../pages/styles/Blog.css';
import BlogCard from '../components/BlogCard';
import axios from './../api/axios';
import { Helmet } from 'react-helmet-async';

export default function BlogCategoryView() {
  const [blogPostsData, setBlogPostsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { category } = useParams();

  useEffect(() => {
    const fetchBlogPostsByCategory = async () => {
      try {
        const response = await axios.get(`/api/get-blogs/category/${encodeURIComponent(category)}`);
        setBlogPostsData(response.data);
        setError(null); // Reset the error state
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error("Failed to fetch blog posts by category:", error);
        }
        if (error.response && error.response.status === 404) {
          setError('No blogs found for this category.');
        } else {
          setError('Something went wrong. Please try again later.');
        }
      }
      setLoading(false);
    };

    fetchBlogPostsByCategory();
  }, [category]);

  const decodedCategory = decodeURIComponent(category);

  return (
    <div className='category-view'>
      <Helmet>
        <title>Category: {decodedCategory} - WattsWebWorks Blog</title>
        <meta name="description" content={`Blogs under category ${decodedCategory}`} />
        <meta property="og:title" content={`Category: ${decodedCategory} - WattsWebWorks Blog`} />
        <meta property="og:description" content={`Explore blogs under the category ${decodedCategory} on WattsWebWorks`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={`https://www.wattswebworks.com/blog/category/${encodeURIComponent(category)}`} />
      </Helmet>

      {/* Main heading for the page */}
      <h1 className='blogs-tag-title'>
        Category:&nbsp;<span className='tagName'>{decodedCategory}</span>
      </h1>

      {/* Link to view all categories - should be a lower-level heading */}
      <h2 className='all-categories-link'>
        <Link to="/blog">View All Categories</Link>
      </h2>

      {/* Result message */}
      <p className='result-message'>
        {error ? <span>{error}</span> : `${blogPostsData.length} ${blogPostsData.length === 1 ? 'Result' : 'Results'}`}
      </p>

      {/* Blog posts */}
      <div className='blogs'>
        {(loading || blogPostsData.length === 0) ? (
          // Render skeleton loaders if loading or no blog posts
          Array.from({ length: 2 }).map((_, index) => (
            <BlogCard key={index} loading={true} isFeatured={index === 0} />
          ))
        ) : (
          blogPostsData.map((post) => (
            <BlogCard key={post.id} {...post} />
          ))
        )}
      </div>
    </div>
  );
}
