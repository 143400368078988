import React from 'react';
import './styles/TosModal.css';

const TosModal = ({ handleClose }) => {

    // Function to prevent click events from propagating to the backdrop
    const handleContentClick = (e) => {
        e.stopPropagation(); // Prevent the modal background click event from being triggered
    };

    return (
        <div onClick={handleClose} className="modal-container">
            <div className='modal' onClick={handleContentClick}>
                <button onClick={handleClose} className="modal-close-button">
                    <i className="fa-solid fa-x"></i>
                </button>
                <h2>Terms of Service & Privacy Policy</h2>
                <p>Last Updated: 9/4/2024</p>

                <h3>1. Acceptance of Terms</h3>
                <p>
                    By accessing or using this site, you agree to these Terms of Service and our Privacy Policy.
                </p>

                <h3>2. Use of Cookies and Data</h3>
                <p>
                    We use cookies to enhance site functionality and analyze user interactions. This includes cookies from third-party services such as Google Analytics and UserWay. These cookies help us understand how users interact with the site and improve your browsing experience. You can opt out of Google Analytics by using the Google Analytics Opt-out Browser Add-on.
                </p>

                <h3>3. Third-Party Services</h3>
                <p>
                    Our site utilizes services like Google Analytics, UserWay, and reCAPTCHA to improve performance, accessibility, and security. These services may collect data such as IP addresses and usage data to help enhance site functionality.
                </p>

                <h3>4. Data Security</h3>
                <p>
                    We take the security of your data seriously. Our site uses HTTPS to ensure that all data transmitted between your browser and our servers is encrypted and secure. Additionally, any information entered on the site is stored securely and in an encrypted manner to protect against unauthorized access. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure.
                </p>

                <h3>5. Changes to Terms and Privacy Policy</h3>
                <p>
                    We reserve the right to modify these terms at any time. Any changes will be posted on this page, and your continued use of the site constitutes acceptance of the updated terms.
                </p>

                <h3>6. Contact Information</h3>
                <p>
                    If you have any questions about these Terms of Service or our Privacy Policy, please contact us at contact@wattswebworks.com.
                </p>

                <h3>7. Copyright Notice</h3>
                <p>
                    All original images, logos, and written content on this site are the property of WattsWebWorks LLC and are protected by copyright laws. Any reproduction, distribution, or use of this content without explicit permission is prohibited. Third-party images are used with permission or under appropriate licenses, remain the property of their respective owners, and are credited where applicable.
                </p>






                {/* Add a "Close" button at the bottom */}
                <div className="modal-bottom-close">
                    <button onClick={handleClose} className="modal-bottom-close-button">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TosModal;
