import React, { useEffect, useState, useContext } from 'react';
import './styles/ContactBar.css';
import { HashLink } from 'react-router-hash-link';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from './../App'; // Import the ThemeContext

const ContactBar = () => {
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();

  // Access theme and toggleTheme from the context
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeFixed = window.scrollY > 400;
      const isMobile = window.innerWidth <= 775;
      const scrollContactBar = ['/', '/about', '/services'].includes(location.pathname);
      setIsFixed(shouldBeFixed && !isMobile && scrollContactBar);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [location.pathname]);

  // Function to handle keyboard interaction for theme toggle
  const handleKeyDown = (e) => {
    // Check for Enter (key code 13) or Space (key code 32) keys
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Prevent default behavior (scrolling for spacebar)
      toggleTheme(); // Toggle theme
    }
  };

  return (
    <>
      {isFixed && <div className="contactbar-placeholder"></div>}
      <div className={`contactbar ${isFixed ? 'contactbar-fixed' : ''}`}>
        <div className="contactBarContent">
          <div className="contact">
            <div className='theme-wrapper'>
              <input
                type="checkbox"
                id="light-switch"
                onChange={toggleTheme} // Hook to toggle between light/dark mode
                checked={theme === 'dark'} // Checked if the theme is dark
                onKeyDown={handleKeyDown} // Handle Enter and Space keypresses
                tabIndex={0} // Ensure it's focusable via Tab
              />
              <label htmlFor="light-switch" id="light-switch-label">
                <div className="switch"></div>
              </label>
            </div>
            <ul className="contactItems" aria-label="Contact information">
              <li className='phone'><a href="tel:304-936-2254" className="phone-number" aria-label="Call us at 304-936-2254"><i className="fa-solid fa-phone"></i><span className='contactItem-text'>304-936-2254</span></a></li>
              <li className='spacer'>|</li>
              <li className='facebookLink'><a href="https://www.facebook.com/WattsWebWorks" aria-label="Visit our Facebook page" rel="noopener noreferrer" target="_blank"><i class="fa-brands fa-square-facebook contactIcon"></i></a></li>
              <li className='spacer'>|</li>
              <li className='phone'><a href="mailto:contact@wattswebworks.com" aria-label="Email us at contact@wattswebworks.com" className="phone-number"><i className="fa-solid fa-envelope"></i><span className='contactItem-text'>contact@wattswebworks.com</span></a></li>
            </ul>
          </div>
          <div className='consultation'>
            <HashLink smooth to="/about#contact">Get a Quote</HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBar;
