import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import axios from './../../api/axios';
import '../styles/BlogsAdmin.css';
import AdminHeader from "./AdminHeader";

export default function BlogsAdmin() {
    const [blogs, setBlogs] = useState([]);
    const [sitemapUrls, setSitemapUrls] = useState([]);

    const fetchBlogs = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-blogs`);
            setBlogs(response.data);
        } catch (error) {
            console.error("Failed to fetch blogs:", error);
        }
    }, []);

    const fetchSitemap = useCallback(async () => {
        try {
            const response = await axios.get(`${window.location.origin}/sitemap.xml`, { responseType: 'text' });
            const urls = parseSitemap(response.data);
            setSitemapUrls(urls);
        } catch (error) {
            console.error("Failed to fetch sitemap:", error);
        }
    }, []);

    useEffect(() => {
        fetchBlogs();
        fetchSitemap();
    }, [fetchBlogs, fetchSitemap]);

    const parseSitemap = (sitemapXml) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(sitemapXml, 'text/xml');
        const urlElements = xmlDoc.getElementsByTagName('loc');
        const urls = Array.from(urlElements).map(urlElement => urlElement.textContent);
        return urls;
    };

    const normalizeTitle = (title) => {
        // Encode the title to handle special characters correctly and match the URL in the sitemap
        return encodeURIComponent(title.trim()).replace(/%20/g, '%20');
    };

    const isBlogInSitemap = (blogTitle) => {
        const normalizedTitle = normalizeTitle(blogTitle);
        // Construct the expected blog URL
        const blogUrl = `https://www.wattswebworks.com/blog/${normalizedTitle}`;
        return sitemapUrls.includes(blogUrl);
    };

    const stripHtml = (html) => {
        const temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    };

    const deleteBlog = async (title) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-blog/${title}`);
            setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.title !== title));
        } catch (error) {
            console.error("Failed to delete blog:", error);
        }
    };

    return (
        <div>
            <AdminHeader />
            <div className="formButtons">
                <Link to="/admin/blogs/new-blog"><button className="add-blog-btn">Add Blog</button></Link>
            </div>
            
            <div className="blogs-list">
                {blogs.map(blog => (
                    <div key={blog.id} className="blog-entry">
                        <div className="blog-date">
                          {new Date(blog.published || blog.date).toLocaleDateString('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                        </div>
                        <p className={`sitemap-status ${isBlogInSitemap(blog.title) ? 'present' : 'absent'}`}>
                            {isBlogInSitemap(blog.title) ? 'In Sitemap' : 'Not in Sitemap'}
                        </p>
                        <h3>{blog.title}</h3>
                        {<img 
                            src={`${process.env.REACT_APP_API_URL}/uploads/blogs/${blog.image}`}
                            alt={blog.imageAlt || "Blog image"}
                            className="blog-image"
                            />
                        }
                        <p>{stripHtml(blog.content).substring(0, 100)}...</p>
                        <Link to={`/admin/blogs/edit-blog/${blog.title}`}><button className="edit-btn">Edit</button></Link>
                        <button onClick={() => deleteBlog(blog.title)} className="delete-btn">Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
}
